RegExp.escape = function(string)
{
	return string.replace(/([.?*+^$[\]\\(){}-])/g, "\\$1");
};

function isZipCode(string)
{
	return !string.search(/^\d{5}$|^[a-zA-Z]{2}-[a-zA-Z\d -]+/);
}

function isEmailAddress(string)
{
	return !string.search(/^[\w\d._%+-]+@[\w\d.-]+\.\w+$/);
}

function isURL(string)
{
	return !string.search(/^(?:https?:\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i);
}

function isPhoneNumber(string)
{
	return !string.search(/^\+?\d[\d-. /]*\d[\d-. /]*$/);
}

function isDate(string)
{
	return !string.search(/^(\d|[012]\d|3[01])\.(\d|0\d|1[012])\.\d{4}$/);
}

// Taken from http://stackoverflow.com/a/1830844
function isNumber(number)
{
	return !isNaN(parseFloat(number)) && isFinite(number);
}

function isCommaNumber(number)
{
	number = String(number).replace(',', '.');
	
	return !isNaN(parseFloat(number)) && isFinite(number);
}

function isInteger(number)
{
	return !isNaN(parseInt(number)) && isFinite(number);
}

function isAcceptable(username, password)
{
    const flaws = passwordFlaws(username, password);

    for (index in flaws)
	{
		if (flaws[index])
		{
			return false;
		}
	}
	
	return true;
}

function passwordFlaws(username, password)
{
    const re = new RegExp(RegExp.escape(username), "i");
    const flaws =
        {
            length: false,
            username: false,
            letters: false,
            uppercase: false,
            lowercase: false,
            numbers: false,
            special: false,
            any: false
        };

    if (password.length < 10)
	{
		flaws.length = true;
	}
	
	if (password.search(re) != -1)
	{
		flaws.username = true;
	}
	
	if (password.search(/[a-z].*[a-z]/) == -1)
	{
		flaws.lowercase = true;
	}
	
	if (password.search(/[A-Z].*[A-Z]/) == -1)
	{
		flaws.uppercase = true;
	}
	
	flaws.letters = flaws.lowercase || flaws.uppercase;
	
	if (password.search(/[0-9].*[0-9]/) == -1)
	{
		flaws.numbers = true;
	}
	
	if (password.search(/[!@#$%^&*?_~].*[!@#$%^&*?_~]/) == -1)
	{
		flaws.special = true;
	}
	
	flaws.any = flaws.length || flaws.username || flaws.letters || flaws.numbers || flaws.special;
	
	return flaws;
}

// The following bits aren't checks but relate to password security. They don't
// warrant their own file, though.
/**
 * Tells how long it takes to crack a password given X attempts per second.
 * @param int entropy The size of the search space
 * @param int guessesPerSecond How many guesses per second the attacker can make
 * @returns string A description of how long it takes to crack the password
 */
function hackDuration(entropy, guessesPerSecond)
{
	return Math.floor(entropy / guessesPerSecond);
}
/**
 * Tells how long it takes to crack a password given X attempts per second.
 * @param int entropy The size of the search space
 * @param int guessesPerSecond How many guesses per second the attacker can make
 * @returns string A description of how long it takes to crack the password
 */
function hackDurationString(duration)
{
	if (duration > 31557600000000)
	{
		return 'über 1 Mio. Jahre';
	}
	
	if (duration > 31557600)
	{
		duration = Math.floor(duration / 31557600);
		return duration + ' Jahr' + (duration != 1 ? 'e' : '');
	}
	
	if (duration > 2592000)
	{
		duration = Math.floor(duration / 2592000);
		return duration + ' Monat' + (duration != 1 ? 'e' : '');
	}
	
	if (duration > 86400)
	{
		duration = Math.floor(duration / 86400);
		return duration + ' Tag' + (duration != 1 ? 'e' : '');
	}
	
	if (duration > 3600)
	{
		duration = Math.floor(duration / 3600);
		return duration + ' Stunde' + (duration != 1 ? 'n' : '');
	}
	
	if (duration > 60)
	{
		duration = Math.floor(duration / 60);
		return duration + ' Minute' + (duration != 1 ? 'n' : '');
	}
	
	if (duration >= 1)
	{
		duration = Math.floor(duration);
		return duration + ' Sekunde' + (duration != 1 ? 'n' : '');
	}
	
	return 'unter einer Sekunde';
}

/**
 * Attempts to guess the size of the search space for a random string used as a
 * password.
 * @param string string The string
 * @return int The size of the search space
 */
function entropy(string)
{
    const characterClasses =
        {
            lowercase: {regexp: /[a-zäöüß]/, size: 30},
            uppercase: {regexp: /[A-ZÄÖÜ]/, size: 29},
            number: {regexp: /[0-9]/, size: 10},
            // The next one contains all other characters; the size is a
            // rough guess at how many special characters are likely to show
            // up in a password.
            special: {regexp: /[^a-zA-Z0-9äöüßÄÖÜ]/, size: 20}
        };

    let spaceSize = 0;

    for (index in characterClasses)
	{
        const characterClass = characterClasses[index];

        if (string.match(characterClass.regexp))
		{
			// We only add half the size of the character class to
			// compensate for the naivety of this approach at
			// entropy estimation.
			spaceSize += characterClass.size / 2;
		}
	}
	
	// On average, an attacker will have to search half the search space
	// before finding the password.
	return Math.pow(spaceSize, string.length) / 2;
}